//引入封装的请求服务
import { POST, UPLOAD } from '@/services/request.js';

//导出接口
const base = {
    register(data = {}) {
        return POST({
            url: '/business/base/register',
            params: data,
        });
    },
    login(data = {}) {
        return POST({
            url: '/business/base/login',
            params: data,
        });
    },
    profile(data = {}) {
        return UPLOAD({
            url: '/business/base/profile',
            params: data,
        });
    },
};

export default base;
