import React from "react";
import { NavBar } from 'react-vant';
import Footer from "./common/Footer";

const Home = () =>
{
    return (
        <>
            <NavBar
                title='首页'
                leftArrow={<></>}
            />

            <div className="banner_shouy">
                <React.Vant.Swiper>
                    {/* {images.map((image) => ( */}
                    <React.Vant.Swiper.Item >
                        {/* <Image lazyload src={image} /> */}
                    </React.Vant.Swiper.Item>
                    {/* ))} */}
                </React.Vant.Swiper>
            </div>

            <div className="shouye_kuanj">
                <div className="swiper-wrapper" style={{display:'flex'}}>
                    <div className="swiper-slide" style={{width:'25%'}}><React.Router.NavLink to="/product/lease/add"><img src="/assets/images/kj.png" alt="" /> <p>产品租赁</p></React.Router.NavLink></div>
                    <div className="swiper-slide" style={{width:'25%'}}><a href="#"><img src="/assets/images/kj1.png" alt="" /> <p>我要归还</p></a></div>
                    <div className="swiper-slide" style={{width:'25%'}}><React.Router.NavLink to="/product/product/index"><img src="/assets/images/kj2.png" alt="" /> <p>全部商品</p></React.Router.NavLink></div>
                    <div className="swiper-slide" style={{width:'25%'}}><a href="#"><img src="/assets/images/kj3.png" alt="" /> <p>关于我们</p></a></div>
                </div>
            </div>

            <div className="chan_p_center">
                <div className="chanp_k_sy">
                    <div className="sjpiue_chanp_list">
                        <h2>手机产品</h2>
                        <React.Vant.Swiper>
                            <React.Vant.Swiper.Item >
                                <div className="swiper-slide"><a href="#" className="a_blok"><img src="/assets/images/chanp_img.jpg" alt="" /> <span>查看详情</span></a></div>
                            </React.Vant.Swiper.Item>
                            <React.Vant.Swiper.Item >
                                <div className="swiper-slide"><a href="#" className="a_blok"><img src="/assets/images/chanp_img1.jpg" alt="" /> <span>查看详情</span></a></div>  
                            </React.Vant.Swiper.Item>
                        </React.Vant.Swiper>
                    </div>
                </div>
            </div>

            <div className="list_color" >
                <ul>
                    <li>
                        <img src="/assets/images/banner.jpg" alt="" />
                        <div className="right">
                            <p>人的美丽会随着时间的流失,会渐渐流失在往日的光彩照人里,只有知识的美丽才是永...</p>
                            <span>博弈论在商业和生活不同场景中的应用指南教程，比《策略思维》有趣、有料、更有挑战性。</span>
                        </div>
                    </li>
                    <li>
                        <img src="/assets/images/banner1.jpg" alt="" />
                        <div className="right">
                            <p>人的美丽会随着时间的流失 </p>
                            <span>博弈论在商业和生活不同场景中的应用指南教程，比《策略思维》有趣、有料、更有挑战性。</span>
                        </div>
                    </li>
                    <li>
                        <img src="/assets/images/anli.jpg" alt="" />
                        <div className="right">
                            <p>人的美丽会随着时间的流失,会渐渐流失在往日的光彩照人里,只有知识的美丽才是永...</p>
                            <span>博弈论在商业和生活不同场景中的应用指南教程，比《策略思维》有趣、有料、更有挑战性。</span>
                        </div>
                    </li>
                    <li>
                        <img src="/assets/images/anli1.jpg" alt="" />
                        <div className="right">
                            <p>人的美丽会随着时间的流失,会渐渐流失在往日的光彩照人里,只有知识的美丽才是永...</p>
                            <span>博弈论在商业和生活不同场景中的应用指南教程，比《策略思维》有趣、有料、更有挑战性。</span>
                        </div>
                    </li>
                </ul>
            </div>

            <div style={{height:".75rem"}}></div>

            <Footer />
        </>
    )
}

export default Home;