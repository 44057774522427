import React from "react";

const Index = () => {
  const Navigate = React.Router.useNavigate();
  const [business] = React.useState(
    React.Cookies.load("business") || {}
  );
  const [list, setList] = React.useState([]);
  const [finished, setFinished] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [limit] = React.useState(10);
  const [status, setStatus] = React.useState(0);

  const ConfirmLease = () => {};

  const getOrderData = async () => {
    let data = {
      busid: business.id,
      page,
      limit,
      status,
    };

    let result = await React.Api.orderIndex(data);

    if (result)
      if (result.code === 1) {
        setList([...list, ...result.data]);

        setTimeout(() => {
          setPage(page + 1);
          setFinished(false);
        }, 1500);
      } else {
        React.Vant.Toast.info(result.msg);
        setFinished(true);
        return;
      }
  };

  const onLoadRefresh = () => {
    setFinished(true);
    getOrderData();
  };

  const onRefresh = () => {
    setPage(1);
    setList([]);
    setFinished(false);

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        getOrderData();
        resolve(true);
      }, 1500);
    });
  };

  // 列表组件
  const Items = () => {
    if (list.length > 0) {
      return list.map((item, key) => {
        return (
          <li key={key}>
            <div className="vip_order_goods">
              <h3>
                <i>
                  <img src={item.product.thumbs[0]} />
                </i>
                <dl>
                  <dt>{item.product.name}</dt>
                  <dd>
                    <em>押金</em>
                    <em>{item.rent}</em>
                  </dd>
                  <dd>
                    <em>总价</em>
                    <em>{item.price}</em>
                  </dd>
                  <dd>
                    <em>结束时间</em>
                    <em>{item.endtime_text}</em>
                  </dd>
                  <dd>
                    <em>订单状态</em>
                    <em>{item.status_text}</em>
                  </dd>
                </dl>
              </h3>
            </div>

            <div className="order_btn">
              {item.status === 5 && (
                <React.Vant.Button
                  round
                  type="warning"
                  size="small"
                  onClick={() => Navigate(`/order/order/comment?id=${item.id}`)}
                >
                  订单评价
                </React.Vant.Button>
              )}

              {(item.status === 3 || item.status === 4) && (
                <React.Vant.Button
                  round
                  type="danger"
                  size="small"
                  onClick={() =>
                    Navigate(`/order/order/recovery?id=${item.id}`)
                  }
                >
                  归还商品
                </React.Vant.Button>
              )}

              {item.status === 2 && (
                <React.Vant.Button
                  round
                  type="warning"
                  size="small"
                  onClick={() => ConfirmLease(item.id)}
                >
                  确认收货
                </React.Vant.Button>
              )}

              {item.status >= 2 && (
                <React.Vant.Button
                  round
                  type="info"
                  size="small"
                  onClick={() => Navigate(`/order/order/express?id=${item.id}`)}
                >
                  查看物流
                </React.Vant.Button>
              )}

              <React.Vant.Button
                round
                type="primary"
                size="small"
                onClick={() => Navigate(`/order/order/info?id=${item.id}`)}
              >
                查看详情
              </React.Vant.Button>
            </div>
          </li>
        );
      });
    }
  };

  const onTab = ({ name }) => {
    setStatus(name);
  };

  React.useEffect(() => {
    setFinished(false);
    setPage(1);
    setList([]);
  }, [status]);

  const onBack = () => {
    Navigate(-1);
  };

  // 选项卡的数据
  const statusList = [
    {
      id: 0,
      name: "全部",
    },
    {
      id: 1,
      name: "已下单",
    },
    {
      id: 2,
      name: "已发货",
    },
    {
      id: 3,
      name: "已收货",
    },
    {
      id: 4,
      name: "已归还",
    },
    {
      id: 5,
      name: "已退押金",
    },
    {
      id: 6,
      name: "已完成",
    },
  ];

  return (
    <>
      <React.Vant.Sticky>
        <React.Vant.NavBar
          title="我的订单"
          leftText="返回"
          onClickLeft={onBack}
        />
      </React.Vant.Sticky>

      <React.Vant.Tabs
        onClickTab={onTab}
        active={status}
      >
        {statusList.map(item => (
          <React.Vant.Tabs.TabPane
            key={item.id}
            title={item.name}
          ></React.Vant.Tabs.TabPane>
        ))}
      </React.Vant.Tabs>

      <React.Vant.PullRefresh onRefresh={onRefresh}>
        {/* List 组件可以与 PullRefresh 组件结合使用，实现下拉刷新的效果 */}
        <React.Vant.List
          className="vip_user_order"
          finished={finished}
          onLoad={onLoadRefresh}
        >
          <ul>
            <Items />
          </ul>
        </React.Vant.List>
      </React.Vant.PullRefresh>
    </>
  );
};

export default Index;
