// 引入每个目录下面的index.js文件
const ModulesFile = require.context('./',false,/.js$/);

const ApiList = {};

ModulesFile.keys().reduce((modules, modulePath) => {
    // 获取文件名
    const ModuleName = modulePath.replace(/^.\/(.*)\.js/, "$1");

    // 不包含当前index.js 文件
    if (ModuleName !== "index") {
        // API请求列表
        const ModuleList = ModulesFile(modulePath);

        // 合并对象 循环多次合并
        Object.assign(ApiList, ModuleList.default);
    }

    return ApiList;
},{});

export default ApiList;