import React from "react";

const Footer = () =>
{
    let location = React.Router.useLocation();
    let navigate = React.Router.useNavigate();

    // 定义变量状态
    const [active,setActive] = React.useState(location.pathname);

    const onActive = (name) =>
    {
        setActive(name);
        navigate(name);
    }

    return (
        <div className="foot_menu">
            <React.Vant.Tabbar activeColor="#ef6382" value={active} onChange={onActive}>
                <React.Vant.Tabbar.Item name="/" icon={<React.Icons.WapHomeO />}>首页</React.Vant.Tabbar.Item>
                <React.Vant.Tabbar.Item name="/category/category/index" icon={<React.Icons.Description />}>学术</React.Vant.Tabbar.Item>
                <React.Vant.Tabbar.Item name="/business/base/index" icon={<React.Icons.UserO />}>我的</React.Vant.Tabbar.Item>
            </React.Vant.Tabbar>
        </div>
    )
}

export default Footer;