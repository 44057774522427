/**
 * AuthRouter是一个用于权限验证的路由器组件。
 * 它根据props.auth的值决定是否需要用户登录，如果需要登录而用户未登录，则重定向到登录页面。
 * @param {Object} props - 组件传入的属性。
 * @param {boolean} props.auth - 指示是否需要用户认证。
 * @param {React.Element} props.component - 需要渲染的组件。
 * @returns {React.Element} 根据认证状态返回相应的组件或重定向到登录页面。
 */
import React from "react";

const AuthRouter = (props) =>
{
    const Auth = () => {
        // 检查是否需要用户认证
        if(props.auth === true)
        {
            // 尝试从cookie加载业务信息
            let business = React.Cookies.load('business') || {};

            // 如果业务信息不存在或为空，则显示登录通知并重定向到登录页面
            if(!business || JSON.stringify(business) === '{}')
            {
                React.Vant.Notify.show({
                    message: '请先登录',
                    type: 'danger',
                    duration: 1000
                });
                return <React.Router.Navigate to="/business/base/login" />
            }

            // 如果用户已认证，渲染指定的组件
            return props.component;

        }else{
            // 不需要认证时，直接渲染指定的组件
            return props.component;
        }
    }

    // 渲染Auth组件
    return (
        <Auth />
    )
}

export default AuthRouter;