import { POST } from '../../services/request';

const category = {
    recommend(data = {})
    {
        return POST({
            url:'category/recommend',
            params:data
        })
    },
    categoryIndex(data = {})
    {
        return POST({
            url:'category/index',
            params:data
        });
    },
    categoryInfo(data = {})
    {
        return POST({
            url:'category/info',
            params:data
        });
    },
    categoryCollection(data = {})
    {
        return POST({
            url:'category/collection',
            params:data
        });
    }
}


export default category;