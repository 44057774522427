import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as Router from 'react-router-dom';
import * as Vant from 'react-vant';
import * as Icons from '@react-vant/icons';
import Cookies from 'react-cookies';
import Api from './api/index';

// 定义全局属性
React.Router = Router;
React.Vant = Vant;
React.Icons = Icons;
React.Cookies = Cookies;
React.Api = Api;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
    <App />
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
