import React from "react";

const Index = () => {
  let navigate = React.Router.useNavigate();
  const [keyword, setKeyword] = React.useState("");
  const [finished, setFinished] = React.useState(false);
  const [page, setPage] = React.useState(1);
  const [limit, setLimit] = React.useState(10);
  const [list, setList] = React.useState([]);
  const [SearchParams, setSearchParams] = React.Router.useSearchParams();
  const [action, setAction] = React.useState(SearchParams.get("action"));

  const onRefresh = () => {
    setFinished(false);
    setPage(1);
    setList([]);

    return new Promise(resolve => {
      setTimeout(() => {
        getProductData();
        resolve(true);
      }, 1500);
    });
  };

  const onLoadRefresh = () => {
    setFinished(true);
    getProductData();
  };

  const getProductData = async () => {
    let data = {
      page,
      limit,
    };

    let result = await React.Api.ProductIndex(data);

    if (result && result.code === 1) {
      setList([...list, ...result.data]);

      setTimeout(() => {
        setFinished(false);
      }, 1500);
      setPage(page + 1);
    } else {
      setFinished(true);
      React.Vant.Toast.info(result.msg);
      return;
    }
  };

  const onSelected = info => {
    if (!info || JSON.stringify(info) === "{}") {
      React.Vant.Toast.fail("选择的商品不存在");
      return;
    }

    React.Cookies.save("product", info, { path: "/" });
    navigate(-1);
  };

  const Items = () => {
    if (list.length > 0) {
      if (action === "lease") {
        return list.map(item => {
          return (
            <li key={item.id}>
              <a onClick={_e => onSelected(item)}>
                <img
                  src={item.thumbs[0]}
                  alt=""
                />
                <p>{item.name}</p>
                <span>￥{item.rent_price}</span><i>库存:{item.stock}</i>
              </a>
            </li>
          );
        });
      } else {
        return list.map(item => {
          return (
            <li key={item.id}>
              <React.Router.NavLink to={"/product/product/info?id=" + item.id}>
                <img
                  src={item.thumbs[0]}
                  alt=""
                />
                <p>{item.name}</p>
                <span>￥{item.rent_price}</span>
              </React.Router.NavLink>
            </li>
          );
        });
      }
    }
  };

  const onBack = () => {
    navigate(-1);
  };

  return (
    <>
      <React.Vant.Sticky>
        <React.Vant.NavBar
          title="产品列表"
          leftText="返回"
          fixed
          onClickLeft={onBack}
        />
        <div style={{ height: ".75rem" }}></div>
        <React.Vant.Search
          value={keyword}
          onChange={setKeyword}
          clearable
          placeholder="请输入搜索关键词"
        />
      </React.Vant.Sticky>
      <div className="left_kuangs" style={{marginTop:".25rem"}}>
        <React.Vant.PullRefresh onRefresh={onRefresh}>
          {/* List 组件可以与 PullRefresh 组件结合使用，实现下拉刷新的效果 */}
          <React.Vant.List
            finished={finished}
            onLoad={onLoadRefresh}
          >
            <ul>
              <Items />
            </ul>
          </React.Vant.List>
        </React.Vant.PullRefresh>
      </div>
    </>
  );
};

export default Index;
