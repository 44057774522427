import { POST } from "../../services/request";

const Order = {
    orderIndex(data = {})
    {
        return POST({
            url:'product/order/index',
            params:data
        });
    }
}

export default Order;