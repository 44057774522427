import React from "react";

const Info = () =>
{
    let navigate = React.Router.useNavigate();

    const [SearchParams,setSearchParams] = React.Router.useSearchParams();
    const [id,setId] = React.useState(SearchParams.get('id') || 0);
    const [info,setInfo] = React.useState({});
    const [prev,setPrev] = React.useState({});
    const [next,setNext] = React.useState({});
    const [business,setBusiness] = React.useState(React.Cookies.load('business') || {});

    const getInfoData = async () =>
    {
        let result = await React.Api.categoryInfo({id,busid:business.id});

        if(result && result.code === 1)
        {
            setInfo(result.data.info);
            setPrev(result.data.prev);
            setNext(result.data.next);
        }else{
            React.Vant.Notify.show({
                type:'warning',
                message:result.msg,
                onClose:() =>{
                    navigate(-1);
                }
            });

            return;
        }
    }

    const Prev = () =>
    {

        const toPrev = () =>
        {
            setId(prev.id);
            navigate('/category/category/info?id=' + prev.id);
        }
        
        if(prev)
        {
            return (
                <p>上一篇：<a onClick={toPrev}>{prev.name}</a></p>
            )
        }else{
            return (
                <p>上一篇：<a>已经是第一篇</a></p>
            )
        }
    }

    const Next = () => {

        const toNext = () =>
        {
            setId(next.id);
            navigate('/category/category/info?id=' + next.id);
        }

        if(next)
        {
            return (
                <p>下一篇：<a onClick={toNext}>{next.name}</a></p>
            )
        }else{
            return (
                <p>下一篇：<a>已经到底了</a></p>
            )
        }
    }

    React.useEffect(() => {
        getInfoData();
    },[id]);

    const onCollection = async () =>
    {
        if(!business || JSON.stringify(business) === '{}')
        {
            React.Vant.Toast.fail('请先登录');
            return;
        }

        let data = {
            id,
            busid:business.id
        }

        let result = await React.Api.categoryCollection(data);

        if(result && result.code === 1)
        {
            if(result.msg.includes('取消收藏成功'))
            {
                info.collection_status = false;

                setInfo(info);
            }else{
                info.collection_status = true;

                setInfo(info);
            }

            navigate(0);
        }else{
            
        }
    }

    const onBack = () =>
    {
        navigate('/category/category/index');
    }

    return (
        <>
            <React.Vant.NavBar
                title="学术详情"
                leftText="返回"
                fixed
                onClickLeft={onBack}
            />

            <div style={{height: ".75rem"}}></div>
            <div className="rem2"></div>
            <div className="xs_Xiangq">
                <div className="bt">
                    <h2>{info.name}</h2>
                    <div className="text">
                        <span>{info.createtime_text}</span>
                        <span>作者：{info.nickname}</span>
                    </div>
                </div>
                <div className="wom_de"  dangerouslySetInnerHTML={{__html:info.description}}>
                    
                </div>
                <div className="niming_sq niming_sq_xs_xq">
                    <a onClick={onCollection}> 
                        {info.collection_status === true ? <React.Icons.Star  /> : <React.Icons.StarO  />}

                        {info.collection_status === true ? '已收藏' : '收藏'}
                    </a>
            
                </div>
            </div>

            <div className="rem2"></div>
            <div className="shangxia_piab">
                <Prev />
                <Next />
            </div>
        </>
    )
}

export default Info;