import Index from "@/components/category/Index";
import Info from "../../components/category/Info";

const Category = [
    {
        path:'category/index',
        name:'CategoryIndex',
        component:Index,
        auth:false
    },
    {
        path:'category/info',
        name:'CategoryInfo',
        component:Info,
        auth:false
    }
];

export default Category;