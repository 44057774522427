import { UPLOAD } from "@/services/request";

const Lease = {
    leaseAdd(data = {})
    {
        return UPLOAD({
            url:'product/lease/add',
            params:data
        });
    }
}

export default Lease;