import React from "react";
import RouterList from './routers/index';

const App = () =>
{
    return (
        <React.Router.BrowserRouter>
            <RouterList />
        </React.Router.BrowserRouter>
    )
}

export default App;