import React from "react";
import Footer from '../common/Footer';

const Index = () =>
{
    let navigate = React.Router.useNavigate();

    // 上拉加载状态
    const [finished,setFinished] = React.useState(false);
    const [recommendList,setRecommendList] = React.useState([]);
    const [list,setList] = React.useState([]);
    const [page,setPage] = React.useState(1);
    const [limit,setLimit] = React.useState(10);

    // const [count,setCount] = React.useState(0);
    // const [num,setNum] = React.useState(0);

    // React.useEffect(() => {
    //     console.log(1);
    // },[count]);

    // React.useEffect(() => {console.log(2)},[num]);

    React.useEffect(() => {
        getRecommendData();
    },[]);

    // 下拉刷新
    const onRefresh = () =>
    {
        setFinished(false);
        setList([]);
        setPage(1);

        return new Promise(resolve => {
            setTimeout(() => {
                getCategoryData();
                resolve(true);
            },2000)
        });
    }

    // 上拉加载
    const onLoadRefresh = () =>
    {
        getCategoryData();
        setFinished(true);
    }


    const getCategoryData = async () => {
        let data = {
            page,
            limit
        }

        let result = await React.Api.categoryIndex(data);

        if(result)
        {
            if(result.code === 1)
            {
                setList([...list,...result.data]);

                setPage(page + 1);
                setFinished(false);
            }else{
                setFinished(true);

                React.Vant.Toast.info(result.msg);

                return;
            }
        }
    }

    const Items = () =>
    {
        if(list.length > 0)
        {
            return list.map(item => {
                return (
                    <li key={item.id}>
                        <React.Router.NavLink to={'/category/category/info?id=' + item.id}>
                            <img src={item.image_cdn} alt="" />
                            <p>{item.name}</p>
                            <span>{item.createtime_text}</span>
                        </React.Router.NavLink>
                    </li>
                )
            })
        }
    }
    

    // 轮播图数据
    const getRecommendData = async () => {
        let result = await React.Api.recommend();

        if(result && result.code === 1)
        {
            setRecommendList(result.data);
        }
    }

    // const onAdd = () => {
    //     setCount(count + 1);
    // }

    // const onTest = () => {
    //     setNum(num + 1);
    // }

    const recommendSwiper = () => {
        if(recommendList.length > 0)
        {
            return (
                <React.Vant.Swiper>
                    {recommendList.map((item) => (
                        <React.Vant.Swiper.Item key={item.id}>
                            <React.Vant.Image lazyload src={item.image_cdn} />
                        </React.Vant.Swiper.Item>
                    ))}
                </React.Vant.Swiper>
                
            )
        }else{
            return (
                <></>
            )
        }
    }

    return (
        <>
            <React.Vant.NavBar
                title="学术"
                leftArrow={<></>}
            />

            <div className="banner_shouy">
                {recommendSwiper()}
            </div>

            <div className="lest_xs">
                <ul>
                <React.Vant.PullRefresh onRefresh={onRefresh}>
                    {/* List 组件可以与 PullRefresh 组件结合使用，实现下拉刷新的效果 */}
                    <React.Vant.List finished={finished} onLoad={onLoadRefresh}>
                        <ul>
                            <Items />
                        </ul>
                    </React.Vant.List>
                </React.Vant.PullRefresh>
                </ul>
            </div>

            {/* <button onClick={onAdd}>点击</button>

            {count}

            <button onClick={onTest}>测试</button>
            {num} */}

            <div style={{height:'.75rem'}}></div>

            <Footer />
        </>
    )
}

export default Index;