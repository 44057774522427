import React from 'react';
import { Routes, Route, Outlet } from 'react-router-dom';
import AuthRouter from './AuthRouter';

// 引入首页
import Home from '@/components/Home';

// 引入每个目录下的入口文件
const Modules = require.context('./',true,/index.js$/);

const RouterMap = [];

Modules.keys().reduce((modules, modulePath) => {
    // 路由模块名称
    const ModuleName = modulePath.replace(/^.\/(.*)\.js/,'$1');

    if(ModuleName !== 'index')
    {
        const list = Modules(modulePath);

        RouterMap.push(...list.default);
    }

    return RouterMap;
},{});

const RouterList = () =>
{
    return (
        <Routes>
            <Route element={<AuthRouter auth={false} component={<Home />} />} path='/' />

            <Route element={<Outlet />} path='/'>
                {
                    RouterMap.map((item,index) => {
                        return (
                            <Route key={index} path={item.path} element={<AuthRouter auth={item.auth} component={<item.component />} />}>
                                {
                                    item.children && item.children.map((son,idx) => {
                                        return (
                                            <Route key={idx} path={son.path} element={<AuthRouter auth={son.auth} component={<son.component />} />} />
                                        )
                                    })
                                }
                            </Route>
                        )
                    })
                }
            </Route>
        </Routes>
    )
}

export default RouterList;