import Index from "@/components/business/base/Index";
import Login from "@/components/business/base/Login";
import Register from "@/components/business/base/Register";
import Profile from "@/components/business/base/Profile";

const Base = [
    {
        path:'base/index',
        name:'BaseIndex',
        component:Index,
        auth: true
    },
    {
        path:'base/login',
        name:'BaseLogin',
        component:Login,
        auth:false
    },
    {
        path:'base/register',
        name:'BaseRegister',
        component:Register,
        auth:false
    },
    {
        path:'base/profile',
        name:'BaseProfile',
        component:Profile,
        auth: true
    },
];

export default Base;