import { POST } from '../../services/request';

const Product = {
    ProductIndex(data = {})
    {
        return POST({
            url:'product/product/index',
            params:data
        });
    }
}

export default Product;