import React from "react";
import { areaList } from "@vant/area-data";

const Profile = () => {
  let navigate = React.Router.useNavigate();

  const [form] = React.Vant.Form.useForm();
  const [business, setBusiness] = React.useState(
    React.Cookies.load("business") || {}
  );
  const [RegionShow, setRegionShow] = React.useState(false);
  const [avatar, setAvatar] = React.useState([
    {
      url: business.avatar_cdn,
    },
  ]);

  const [code, setCode] = React.useState([
    business.province,
    business.city,
    business.district,
  ]);

  const onRegionConfirm = (_, values) => {
    let [province, city, district] = values;

    if (!province || !city || !district) {
      return;
    }

    let regionText = "";
    let regionCode = [];

    if (province.value) {
      regionText = province.text + "-";
      regionCode.push(province.value);
    }

    if (city.value) {
      regionText += city.text + "-";
      regionCode.push(city.value);
    }

    if (district.value) {
      regionText += district.text;
      regionCode.push(district.value);
    }

    setRegionShow(false);

    business.region_text = regionText;

    // let info = business;

    // info.region_text = regionText;

    // setBusiness(info);

    setBusiness(business);

    setCode(regionCode);
  };

  const onSubmit = async values => {
    let data = {
      busid: business.id,
      nickname: values.nickname,
      email: values.email,
      gender: values.gender,
    };

    if (values.password) {
      data.password = values.password;
    }

    let avatar = values.avatar[0].file ?? "";

    if (avatar) {
      data.avatar = avatar;
    }

    let [province, city, district] = code;

    let regionCode = district ? district : city ? city : province;

    if (regionCode) {
      data.code = regionCode;
    }

    let result = await React.Api.profile(data);

    if (result && result.code === 1) {
      React.Vant.Notify.show({
        type: "success",
        message: result.msg,
        duration: 1500,
        onClose: () => {
          React.Cookies.save("business", result.data, { path: "/" });

          navigate(-1);
        },
      });

      return;
    } else {
      React.Vant.Notify.show({
        type: "warning",
        message: result.msg,
        duration: 1500,
      });

      return;
    }
  };

  const FormFooter = () => {
    return (
      <div style={{ margin: "16px 16px 0" }}>
        <React.Vant.Button
          round
          nativeType="submit"
          type="primary"
          block
        >
          提交
        </React.Vant.Button>
      </div>
    );
  };

  const onBack = () => {
    navigate(-1);
  };

  return (
    <>
      <React.Vant.NavBar
        title="修改资料"
        leftText="返回"
        onClickLeft={onBack}
      />

      <React.Vant.Form
        form={form}
        onFinish={onSubmit}
        footer={<FormFooter />}
        colon
        labelAlign="right"
      >
        <React.Vant.Form.Item
          rules={[{ required: true, message: "请填写昵称" }]}
          name="nickname"
          label="昵称"
          initialValue={business.nickname || ""}
        >
          <React.Vant.Input placeholder="请输入昵称" />
        </React.Vant.Form.Item>

        <React.Vant.Form.Item
          rules={[{ required: true, message: "请填写邮箱" }]}
          name="email"
          label="邮箱"
          initialValue={business.email || ""}
        >
          <React.Vant.Input placeholder="请输入邮箱" />
        </React.Vant.Form.Item>

        <React.Vant.Form.Item
          name="password"
          label="密码"
        >
          <React.Vant.Input
            type="password"
            placeholder="密码为空不修改密码"
          />
        </React.Vant.Form.Item>

        <React.Vant.Form.Item
          name="gender"
          label="性别"
          initialValue={business.gender}
        >
          <React.Vant.Radio.Group direction="horizontal">
            <React.Vant.Radio name="0">保密</React.Vant.Radio>
            <React.Vant.Radio name="1">男</React.Vant.Radio>
            <React.Vant.Radio name="2">女</React.Vant.Radio>
          </React.Vant.Radio.Group>
        </React.Vant.Form.Item>

        <React.Vant.Form.Item
          label="选择地区"
          onClick={() => {
            setRegionShow(true);
          }}
          isLink
        >
          <React.Vant.Input
            placeholder="请选择地区"
            readOnly
            value={business.region_text}
          />
        </React.Vant.Form.Item>

        <React.Vant.Popup
          position="bottom"
          round
          visible={RegionShow}
          onClose={() => {
            setRegionShow(false);
          }}
        >
          <React.Vant.Area
            title="选择地区"
            areaList={areaList}
            value={code}
            onCancel={() => {
              setRegionShow(false);
            }}
            onConfirm={onRegionConfirm}
          />
        </React.Vant.Popup>

        <React.Vant.Form.Item
          name="avatar"
          label="头像"
          initialValue={avatar}
        >
          <React.Vant.Uploader maxCount={1} />
        </React.Vant.Form.Item>
      </React.Vant.Form>
    </>
  );
};

export default Profile;
